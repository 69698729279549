import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import Loading from "common/components/atoms/Loading"
import "./assets/style/defaultStyle.scss"

const LazyApp = lazy(() => import("./App"))
ReactDOM.render(
    // <React.StrictMode>
    <Suspense fallback={<Loading />}>
        <LazyApp />
    </Suspense>
    // </React.StrictMode>
    ,
    document.getElementById("root")
);
reportWebVitals();
